export const HOME = '/';
export const CONTACT = '/contact';
export const COMPANYDATA = '/companydata';
export const MISSION = '/mission';
export const NEWS = '/news';
export const GALLERY = '/gallery';
export const WILD = '/wild';
export const MOKATE = '/mokate';
export const SUPPERFOOD = '/supperfood';
export const MPROFOOD = '/mprofood';
export const CAREER = '/career';